import { mapState } from 'vuex'
import config from '@/../config'

export default {
  name: 'CheckoutCart',
  sspayMode: false,
  data () {
    return {
      email: '',
      cdnUrlPrefix: config.cdnUrlPrefix
    }
  },
  props: [
    'shippingCost',
    'subTotal',
    'order',
    'activeShippingId',
    'getPrice',
    'debugLog',
    'getAddPrice',
    'setMoreInfoData',
    'calculateDiscount',
    'deleteItemSsv4Request',
    'domain'
  ],
  computed: {
    ...mapState({
      user: (state) => ({
        ...state.user
      }),
      basket: (state) => ({...state.basket}),
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      integrationHost: (state) => state.integrationHost
    })
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    calculateItemPrice (item) {
      let price = 0
      price += parseFloat(item.price)
      price += (item.prescription && item.prescription.info.prices_gbp) ? parseFloat(item.prescription.info.prices_gbp.lens) : 0
      price += (item.prescription && item.prescription.info.prices_gbp) ? parseFloat(item.prescription.info.prices_gbp.tints) : 0
      price += (item.prescription && item.prescription.info.prices_gbp && item.prescription.info.prismes.length > 0) ? parseFloat(item.prescription.info.prices_gbp.prism) : 0
      return this.getPrice(price)
    },
    loadDataToPopup: function (data) {
      this.setMoreInfoData(data)
    },
    deleteItemSsv4: function (index, itemId) {
      this.deleteItemSsv4Request(index, itemId)
    },
    restrictionIndex: function (item) {
      return (item.has_restrictions && item.restrictions.length > 0) ? item.restrictions[0].restriction : -1
    },
    sortByRestriction: function () {
      const self = this
      this.order.orderItems.sort(function (item1, item2) {
        return self.restrictionIndex(item1) - self.restrictionIndex(item2)
      })
    }
  },
  created: function () {
    this.debugLog('CheckoutCart created', {
      'shippingCost': this.shippingCost,
      'subTotal': this.subTotal,
      'activeShippingId': this.activeShippingId
    })
    this.sspayMode = (this.$route.query.sspay !== undefined)
    this.sortByRestriction()
  }
}
